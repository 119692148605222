<template>
    <div>
    
        <v-app-bar app clipped-left :color="color" dense dark style="overflow-x: auto;">
            <v-app-bar-nav-icon to="/"><v-img :src="logoIconUrl" width="10px" alt="wwcalc"/></v-app-bar-nav-icon>
            <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab key="capex">Capex</v-tab>
            <v-tab key="expropriation">{{ selectedLanguage['adjustPricesDefault_component_text1'] }}</v-tab>
            <v-tab key="curves">{{ selectedLanguage['adjustPricesDefault_component_text2'] }}</v-tab>
            <v-tab key="platform">{{ selectedLanguage['adjustPricesDefault_component_text3'] }}</v-tab>
            <v-tab key="earthwork">{{ selectedLanguage['adjustPricesDefault_component_text4'] }}</v-tab>
            </v-tabs>
            <v-btn :loading="loading1" text @click="exitEdition()">
            {{ selectedLanguage['adjustPricesDefault_component_text5_2'] }}
            </v-btn>
            <v-btn :loading="loading1" text @click="clickSaveExit()">
            {{ selectedLanguage['adjustPricesDefault_component_text5'] }}
            </v-btn>
        </v-app-bar>

        <!-- <div v-for="(item, idx) in initialList" :key="idx">
            <div :class="idx+' selecao'">
                <v-select label="Select" :items="item" v-model="itemOnList"></v-select>
                <v-btn @click="exitSelecao()">Cancel</v-btn>
                <v-btn @click="saveSelecao(itemOnList)">Save</v-btn>
            </div>
        </div> -->

        <div v-for="(item, idx) in initialList" :key="idx">
            <div :class="idx+' selecao'">
                <!-- <v-select label="Select" :items="item" v-model="itemOnList"></v-select> -->
                <div style="display: flex; flex-direction: column; overflow-y: auto; max-height: 90%; padding: 20px;">
                    <div style="display: flex; flex-direction: row; font-weight: bold; font-size: 16px; justify-content: space-between;">
                    <p style="max-width: 60%; width: 60%;"> {{ selectedLanguage['Select_capex_dialog_texts_1'] }} </p>
                    <p style="max-width: 20%; width: 20%; display: flex; flex-direction: row; justify-content: right;"> {{ selectedLanguage['Select_capex_dialog_texts_2'] }} </p>
                    <p style="max-width: 20%; width: 20%; display: flex; flex-direction: row; justify-content: right;"> {{ selectedLanguage['Select_capex_dialog_texts_3'] }} </p>
                    </div>
                <v-radio-group v-for="(obj, index) in item" :key="index" v-model="item[idenx]">
                    <div style="display: flex; flex-direction: row; justify-content: space-between;">
                    <v-radio :label="obj[0]" :value="obj" style="max-width: 60%; width: 60%;"></v-radio>
                    <p style="max-width: 20%; width: 20%; display: flex; flex-direction: row; justify-content: right;">{{ obj[1] }}</p>
                    <p style="max-width: 20%; width: 20%; display: flex; flex-direction: row; justify-content: right;">{{ obj[2] }}</p>
                    </div>
                </v-radio-group>
                </div>
                <div style="position: relative; z-index: 999; background-color: #0a7373; display: flex; flex-direction: row; width: 100%; padding: 10px;">
                <v-btn @click="exitSelecao()" style="margin-right: 10px;">{{ selectedLanguage['Select_capex_dialog_texts_4'] }}</v-btn>
                <v-btn @click="saveSelecao(item[idenx])">{{ selectedLanguage['Select_capex_dialog_texts_5'] }}</v-btn>
                </div>
            </div>
        </div>
    
        <v-card v-if="paramSelected" class="elevation-0">
        <v-tabs-items v-model="tab">
        <v-tab-item key="capex">
                <v-card-text>
                <v-data-table :headers="capexHeaders" :items="selectedCapex" hide-default-footer disable-pagination item-key="name" dense class="elevation-0">
                    <template #item="{ item }">
                    <tr v-if="item.enable" :class="capexItemStyle(item)+' trBody'">
                        <td class="codeS">
                        {{item['Code']}}
                        </td>
                        <td style="height: auto;" :id="item['Code']" :class="item['Code']+'value'">
                        {{item['Description']}}
                        </td>
                        <td v-if="item.Unit!=0" :class="item['Code']+'value'">
                        {{item['Unit']}}
                        </td>
                        <td v-else>
                        </td>
                        <td v-if="item.Unit!=0">
                        <v-edit-dialog :class="item['Code']+'value'" :return-value.sync="item['Unit Cost']" @save="onSave(item, 'Unit Cost')" :save-text="selectedLanguage['capex_dialog_text_btn_1']" :cancel-text="selectedLanguage['capex_dialog_text_btn_2']" @cancel="cancel" @open="open" @close="close" large> {{item['Unit Cost']}}
                            <template v-slot:input>
                            <v-text-field :rules="[validateInput]" @blur="validateAndFix(item, 'Unit Cost')"
                            :class="item['Code']+'value'" v-model.number="item['Unit Cost']" label="Edit" single-line></v-text-field>
                            </template>
                        </v-edit-dialog>
                        </td>
                        <td v-else>
                        </td>
                        <td v-if="item.Unit!=0">
                        <v-edit-dialog :return-value.sync="item['Risk']" @save="onSave(item, 'Risk')" :save-text="selectedLanguage['capex_dialog_text_btn_1']" :cancel-text="selectedLanguage['capex_dialog_text_btn_2']" @cancel="cancel" @open="open" @close="close" large> {{item['Risk']}}
                            <template v-slot:input>
                            <v-text-field :rules="[validateInput]" @blur="validateAndFix(item, 'Risk')"
                            v-model.number="item['Risk']" label="Edit" single-line></v-text-field>
                            </template>
                        </v-edit-dialog>
                        </td>
                        <td v-else>
                        </td>
                    </tr>
                    </template>
                </v-data-table>
                </v-card-text>
            </v-tab-item>
    
            <v-tab-item key="expropriation">
                <v-card-text>
                <v-data-table :headers="expropriationHeaders" :items="selectedExpropriation" hide-default-footer disable-pagination item-key="Code" dense class="elevation-0">
                    <template #item="{ item }">
                    <tr :class="expropriationItemStyle(item)+' trBody'">
                        <td class="codeS">
                        {{item['Code']}}
                        </td>
                        <td style="height: auto;">
                        {{item['Description']}}
                        </td>
                        <td>
                        <v-edit-dialog :return-value.sync="item['Cost']" @save="onSave(item, 'Cost')" :save-text="selectedLanguage['capex_dialog_text_btn_1']" :cancel-text="selectedLanguage['capex_dialog_text_btn_2']" @cancel="cancel" @open="open" @close="close" large> {{item['Cost']}}
                            <template v-slot:input>
                            <v-text-field :rules="[validateInput]" @blur="validateAndFix(item, 'Cost')"
                            v-model.number="item['Cost']" label="Edit" single-line></v-text-field>
                            </template>
                        </v-edit-dialog>
                        </td>
                    </tr>
                    </template>
                </v-data-table>
                </v-card-text>
            </v-tab-item>
                <v-tab-item key="curves">
            <v-card-text>
                <Curves />
            </v-card-text>
            </v-tab-item>
            <v-tab-item key="platform">
            <v-card-text>
                <v-row>
                <v-col style="align-self: center">
                    <v-select
                    outlined
                    v-model="centralBarrierSwitch"
                    :items="selectedLanguage['adjustPricesDefault_component_text6']"
                    item-value="id"
                    :label="selectedLanguage['adjustPricesDefault_component_text7']"
                    @input="onCentralBSelected"
                    />
                    <v-text-field
                    outlined
                    v-model="paramSelected.values_params.platform.lanes"
                    id="Lanes"
                    :label="selectedLanguage['adjustPricesDefault_component_text8']"
                    @input="onLanesSet"
                    />
                    <v-select disabled
                    outlined
                    v-model="
                        paramSelected.values_params.superstructure.central_barrier
                    "
                    :items="selectedLanguage['adjustParameters_component_text7']"
                    item-text="name"
                    item-value="id"
                    :label="selectedLanguage['adjustPricesDefault_component_text9']"
                    @input="onCentralBarrierSelected"
                    v-if="centralBarrierSwitch == this.selectedLanguage['adjustPricesDefault_component_text13']"
                    />
                    <v-select
                    outlined
                    v-model="
                        paramSelected.values_params.superstructure.central_barrier
                    "
                    :items="selectedLanguage['adjustPricesDefault_component_text10']"
                    item-text="name"
                    item-value="id"
                    :label="selectedLanguage['adjustPricesDefault_component_text9']"
                    @input="onCentralBarrierSelected"
                    v-if="centralBarrierSwitch == this.selectedLanguage['adjustPricesDefault_component_text12']"
                    />
                    <v-select
                    outlined
                    v-model="
                        paramSelected.values_params.superstructure.side_barrier
                    "
                    :items="selectedLanguage['adjustPricesDefault_component_text10']"
                    item-text="name"
                    item-value="id"
                    :label="selectedLanguage['adjustPricesDefault_component_text11']"
                    @input="onSideBarrierSelected"
                    />
                </v-col>
                </v-row>

                <PlatformAquisition1Lanes v-if="centralBarrierSwitch == this.selectedLanguage['adjustPricesDefault_component_text13']" :params="selectedOptions"  />
                <PlatformAquisition2Lanes v-else :params="selectedOptions"  />

                <br /><v-divider /><br />
                <v-row v-if="centralBarrierSwitch == this.selectedLanguage['adjustPricesDefault_component_text13']" >
                <v-col></v-col>
                <v-col cols="12">
                    <Platform1Lane :params="selectedOptions" />
                </v-col>
                <v-col></v-col>
                </v-row>
                <v-row v-else>
                <v-col></v-col>
                <v-col cols="12">
                    <Platform2Lanes :params="selectedOptions" :callWatcher="callWatcher(this.paramSelected.values_params.platform.median_width)" />
                </v-col>
                <v-col></v-col>
                </v-row>
                <br /><v-divider /><br />
                <PlatformBridge />
                <br />
                <v-col cols="12">
                <v-spacer />
                <PlatformBridge1Lane
                    v-if="centralBarrierSwitch == this.selectedLanguage['adjustPricesDefault_component_text13']"
                    :params="selectedOptions"
                />
                <PlatformBridge2Lanes v-else :params="selectedOptions" />
                <v-spacer />
                </v-col>
                <br /><v-divider /><br />
                <PlatformTunnel />
                <br /><br />
                <v-col cols="12">
                <v-spacer />
                <PlatformTunnel1Lane
                    v-if="centralBarrierSwitch == this.selectedLanguage['adjustPricesDefault_component_text13']"
                    :params="selectedOptions"
                />
                <PlatformTunnel2Lanes v-else :params="selectedOptions" />
                <v-spacer />
                </v-col>
            </v-card-text>
            </v-tab-item>
            <v-tab-item key="earthwork">
            <v-card-text>
                <br />
                <br />
                <EarthworkTransport />
                <br /><v-divider /><br />
                <EarthworkCut1Lane
                v-if="centralBarrierSwitch == this.selectedLanguage['adjustPricesDefault_component_text13']"
                :params="selectedOptions"
                />
                <EarthworkCut2Lanes v-else :params="selectedOptions" />
                <br /><v-divider /><br />
                <EarthworkFill1Lane
                v-if="centralBarrierSwitch == this.selectedLanguage['adjustPricesDefault_component_text13']"
                :params="selectedOptions"
                />
                <EarthworkFill2Lanes v-else :params="selectedOptions" />
            </v-card-text>
            </v-tab-item>
            </v-tabs-items>
        </v-card>
        </div>
    </template>
    
    <script>
        import { mapActions, mapGetters } from 'vuex'
        import Curves from "./paramsDefaults/Curves.vue";
        import PlatformAquisition1Lanes from "./paramsDefaults/PlatformAquisition1Lanes.vue";
        import PlatformAquisition2Lanes from "./paramsDefaults/PlatformAquisition2Lanes.vue";
        import Platform1Lane from "./paramsDefaults/Platform1Lane.vue";
        import Platform2Lanes from "./paramsDefaults/Platform2Lanes.vue";
        import PlatformBridge from "./paramsDefaults/PlatformBridge.vue";
        import PlatformBridge1Lane from "./paramsDefaults/PlatformBridge1Lane.vue";
        import PlatformBridge2Lanes from "./paramsDefaults/PlatformBridge2Lanes.vue";
        import PlatformTunnel from "./paramsDefaults/PlatformTunnel.vue";
        import PlatformTunnel1Lane from "./paramsDefaults/PlatformTunnel1Lane.vue";
        import PlatformTunnel2Lanes from "./paramsDefaults/PlatformTunnel2Lanes.vue";

        import EarthworkTransport from "./paramsDefaults/EarthworkTransport.vue";
        import EarthworkCut1Lane from "./paramsDefaults/EarthworkCut1Lane.vue";
        import EarthworkCut2Lanes from "./paramsDefaults/EarthworkCut2Lanes.vue";
        import EarthworkFill1Lane from "./paramsDefaults/EarthworkFill1Lane.vue";
        import EarthworkFill2Lanes from "./paramsDefaults/EarthworkFill2Lanes.vue";

        import { portuguese } from '../../../languages/pt-br';
        import { english } from '../../../languages/en';

        import tippy from 'tippy.js';
        import 'tippy.js/dist/tippy.css';
    
        export default {
        name: 'AdjustPrices',
        components: {
            Curves,
            EarthworkTransport,
            EarthworkCut1Lane,
            EarthworkCut2Lanes,
            EarthworkFill1Lane,
            EarthworkFill2Lanes,
            Platform1Lane,
            Platform2Lanes,
            PlatformAquisition1Lanes,
            PlatformAquisition2Lanes,
            PlatformBridge,
            PlatformBridge1Lane,
            PlatformBridge2Lanes,
            PlatformTunnel,
            PlatformTunnel1Lane,
            PlatformTunnel2Lanes,
        },
        async mounted(){
            setTimeout(()=>{
                this.setSelectsOnTable()
                this.setToolTipOnTable()
            },5000)
            await this.getConfigById({id: this.$route.params.id})
            this.paramSelected.values_params.superstructure.central_barrier = this.selectedLanguage['adjustPricesDefault_component_text14'];
            this.paramSelected.values_params.superstructure.side_barrier = this.selectedLanguage['adjustPricesDefault_component_text14'];

            await this.iniciarCentralBarrierSwitch();
            this.iniciarCentral_barrier();
            this.iniciarSide_barrier();
        },
        computed: {
            steps () {
            return this.$store.getters.steps('railway')
            },
            status () {
            return this.$store.getters.status(this.$route.params.id)
            },
            ...mapGetters(['selectedExpropriation','paramSelected', 'selectedCapex','profile']),
            expropriationHeaders () {
            try{
                return [
                { text: 'Code', value: 'Code', sortable: false},
                { text: 'Description', value: 'Description', sortable: false},
                { text: 'Cost ('+this.paramSelected.currency+'/m²)', value: 'Cost', sortable: false},
                ]
            } catch(error) {
                return [
                { text: 'Name', value: 'name', sortable: false},
                { text: 'Value (/m²)', value: 'value', sortable: false},
                ]
            }
            },
            capexHeaders () {
            
                return [
                { text: 'Code', value: 'Code', sortable: false},
                { text: 'Description', value: 'Description', sortable: false},
                { text: 'Unit', value: 'Unit', sortable: false},
                { text: 'Unit Cost', value: 'Unit Cost', sortable: false},
                { text: 'Risk (%)', value: 'Risk', sortable: false},
                ]
            },
            selectedLanguage() {
                if (this.profile['language'] === 'en') {
                    return english;
                } else {
                    return portuguese;
                }
            },
        },
        data() {
            return {
                logoIconUrl: '/img/icons/logo_icon.webp',
                permanent: false,
                drawer: true,
                tab: 'expropriation',
                color: '#0A7373',
                loading1: false,
                loading2: false,
                idenx: undefined,
                centralBarrier: false,
                sideBarrier: true,
                centralBarrierSwitch: 'No',
                selectedOptions: {
                    centralBarrierSwitch: 'No',
                    central_barrier: "None",
                    side_barrier: "None",
                },
                callWatch: undefined,
                capexListSelected: undefined,
                capexSelectList: undefined,
                selectToSetCapexList: undefined,
                initialList: undefined,
                capexSelect: false,
                itemOnList: undefined,
                itemOnList2: undefined,
                itemOnList3: undefined,
                itemOnList4: undefined,
            };
        },
        methods: {

        validateInput(value) {
            value = String(value)
            if (value === null || value === undefined || value.trim() === '') {
            return 'Value cannot be empty'
            }
            if (isNaN(value)) {
            return 'Value must be a number'
            }
            if (Number(value) < 0) {
            return 'Value cannot be less than 0'
            }
            return true
        },
        validateAndFix(item, field) {
            item[field] = String(item[field])
            if (item[field] === null || item[field] === undefined || item[field].trim() === '') {
            item[field] = 0
            }
        },
        onSave(item, field) {
            const validationMessage = this.validateInput(item[field])
            if (validationMessage !== true) {
            alert(validationMessage)
            return
            }
            // this.validateAndSave(item)
        },
        // validateAndSave(item) {
        //     console.log('Saving item:', item)
        // },
        async setSelected(){
            if(!localStorage.getItem("selectedItem")){
                this.selectToSetCapexList = this.selectitemBackup
                } else if(!JSON.parse(localStorage.getItem("selectedItem"))){
                this.selectToSetCapexList = this.selectitemBackup
                } else{
                this.selectToSetCapexList = await JSON.parse(localStorage.getItem("selectedItem"))
                }
        },
        async exitSelecao(){
            if(!localStorage.getItem("selectedItem")){
                this.selectToSetCapexList = this.selectitemBackup
                } else if(!JSON.parse(localStorage.getItem("selectedItem"))){
                this.selectToSetCapexList = this.selectitemBackup
                } else{
                this.selectToSetCapexList = await JSON.parse(localStorage.getItem("selectedItem"))
                }
            document.getElementsByClassName(this.selectToSetCapexList)[0].style.display = 'none'
        },
        async saveSelecao(item){

            var elementSelected = JSON.parse(window.localStorage.getItem('selectedItem'))
            // console.log('Selected Item:', elementSelected)
            var element = document.getElementById(elementSelected)
            // console.log('element is', element, 'is', elementSelected)

            var indexIs = this.paramSelected.capex.CAPEX.Code.indexOf(this.selectToSetCapexList.toString())

            this.selectedCapex[indexIs]['Description'] = item[0]
            document.getElementsByClassName(this.selectToSetCapexList+'value')[0].textContent = item[0]

            this.selectedCapex[indexIs]['Unit'] = item[1]
            document.getElementsByClassName(this.selectToSetCapexList+'value')[1].textContent = item[1]

            this.selectedCapex[indexIs]['Unit Cost'] = item[2]
            // document.getElementsByClassName(this.selectToSetCapexList+'value')[2].textContent = item[2]

            document.getElementsByClassName(this.selectToSetCapexList)[0].style.display = 'none'

            var icon = document.createElement('span')
            icon.className = 'mdi mdi-pencil'

            element.insertBefore(icon, element.firstChild)

        },

        async setSelectsOnTable(){

            var selectToSetCapexList;

            var ListenerKeys = []
            ListenerKeys = Object.keys(this.$store.getters.paramSelected.capex.Variables)
            var self = this;
            for(let i = 0; i < ListenerKeys.length; i++){
            document.getElementById(ListenerKeys[i]).style.cursor = 'pointer'
            document.getElementById(ListenerKeys[i]).addEventListener('click', function(e) {
                selectToSetCapexList = e.target.id
                window.localStorage.setItem('selectedItem', ('"'+selectToSetCapexList+'"'))
                this.selectitemBackup = ('"'+selectToSetCapexList+'"')
                self.setSelected()
                document.getElementsByClassName(e.target.id)[0].style.display = 'block'
            });
            var element = document.getElementById(ListenerKeys[i])

            var icon = document.createElement('span')
            icon.className = 'mdi mdi-pencil'

            element.insertBefore(icon, element.firstChild)
            }

        },
        async setToolTipOnTable() {
            var ListenerKeys = [];
            ListenerKeys = Object.keys(this.$store.getters.paramSelected.capex.Details);

            for (let i = 0; i < ListenerKeys.length; i++) {
                let element = document.getElementById(ListenerKeys[i])

                if (element){
                    let ObjtooltipText = this.$store.getters.currentCalc.capex.Details[ListenerKeys[i]]
                    var ArrtooltipText = '';
                    for(let j = 0; j < ObjtooltipText.length ; j++){
                        ArrtooltipText += this.$store.getters.currentCalc.capex.Details[ListenerKeys[i]][j]+'<br>'
                    }
                    // Substitui os espaços por quebras de linha (se necessário)
                    // var newtxt = ArrtooltipText.replaceAll('|', '<br>')

                    let tooltipIcon = document.createElement('i')
                    tooltipIcon.className = "mdi mdi-information"
                    tooltipIcon.style.cursor = "pointer"
                    tooltipIcon.style.marginLeft = "10px"
                    tooltipIcon.style.fontSize = "20px"

                    element.appendChild(tooltipIcon)

                    // Usa Tippy.js para criar um tooltip personalizado
                    tippy(tooltipIcon, {
                        content: ArrtooltipText,
                        allowHTML: true
                    })
                }
            }
        },
        iniciarCentralBarrierSwitch(){

        this.initialList = this.$store.getters.paramSelected.capex.Variables

        if(this.paramSelected.values_params.platform.median_width > 0){
            this.centralBarrierSwitch = this.selectedLanguage['adjustParameters_component_text15'];
            this.callWatch = this.selectedLanguage['adjustParameters_component_text15'];
        } else {
            this.centralBarrierSwitch = this.selectedLanguage['adjustParameters_component_text14'];
            this.callWatch = this.selectedLanguage['adjustParameters_component_text14'];
        }

        this.centralBarrierSwitch = this.selectedLanguage['adjustParameters_component_text14'];
        this.selectedOptions.centralBarrierSwitch = this.selectedLanguage['adjustParameters_component_text14'];
        },
        iniciarCentral_barrier(){ return this.selectedOptions.central_barrier = this.selectedLanguage['adjustParameters_component_text16'];},
        iniciarSide_barrier(){ return this.selectedOptions.side_barrier = this.selectedLanguage['adjustParameters_component_text16'];},
        redirectFunc(){
        if(process.env.VUE_APP_VAR_SELECT== 'prd'){
            this.$router.push({ name: 'billingMercadoPago' })
            } else {
            this.$router.push({ name: 'billing' })
            }
        },
        callWatcher(value){
            this.callWatch = value
        },
        ...mapActions(['getConfigById','updateSelectedCapex','updateSelectedRasterBudget','updateSelectedParams']),
        save() {},
        cancel() {},
        open() {},
        close() {},
        expropriationItemStyle(item) {
        if(item.Cost!=undefined){ return 'capexLastLevel'}
        if(item.Level==0){ return 'capexZeroLevel'}
        if(item.Level==1){ return 'capexFirstLevel'}
        else{ return 'capexLastLevel'}
        },
        capexItemStyle(item) {
        if(item.Unit!=0){ return 'capexLastLevel'}
        if(item.Level==0){ return 'capexZeroLevel'}
        if(item.Level==1){ return 'capexFirstLevel'}
        if(item.Level==2){ return 'capexSecondLevel'}
        if(item.Level==3){ return 'capexThirdLevel'}
        return ''
        },
        exitEdition(){
            this.$router.push('/type/new_roadway')
        },
        async clickSaveExit() {
        this.loading1 = true
        await (
            this.updateSelectedRasterBudget(),
            this.updateSelectedCapex(),
            this.updateSelectedParams()
        )
        this.loading1 = false
        this.$router.push('/type/new_roadway')
        },
        async clickCancelExit() {
        this.loading2 = true
        await this.goStep(1)
        this.loading2 = false
        },
        onCentralBSelected(value) {
        if(value === this.selectedLanguage['adjustPricesDefault_component_text13']){
            this.centralBarrier = false
            this.paramSelected.values_params.superstructure.central_barrier = this.selectedLanguage['adjustParameters_component_text16'];
            this.selectedOptions.central_barrier = this.selectedLanguage['adjustParameters_component_text16'];
        } else {
            this.centralBarrier = true
        }
        // this.paramSelected.values_params.platform.lanes = value;
        this.selectedOptions.centralBarrierSwitch = value;
        this.callWatch = value
        },
        onLanesSet(value){
        this.paramSelected.values_params.platform.lanes = Number(value);
        this.callWatch = Number(value)
        },
        onCentralBarrierSelected(value) {
        this.paramSelected.values_params.superstructure.central_barrier = value;
        this.selectedOptions.central_barrier = value;
        this.callWatch = value
        },
        onSideBarrierSelected(value) {
        this.paramSelected.values_params.superstructure.side_barrier = value;
        this.selectedOptions.side_barrier = value;
        this.callWatch = value
        },
    },
    watch:{
        callWatch:{
        handler(){
            // no central barrier
            if( this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustPricesDefault_component_text13'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustPricesDefault_component_text14'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustPricesDefault_component_text14'] && this.paramSelected.values_params.platform.include_gutter_calc == true){
            this.paramSelected.values_params.imgs.aquisition = 'Road_and_lanes_1_lane'
            this.paramSelected.values_params.imgs.bridge = 'bridge_section_1_lane'
            this.paramSelected.values_params.imgs.cut = 'cut_1_lane'
            this.paramSelected.values_params.imgs.fill = 'fill_1_lane'
            this.paramSelected.values_params.imgs.roads = 'Road_and_lanes_1_lane'
            this.paramSelected.values_params.imgs.tunnel = 'tunnel_section_1_lane'
            this.paramSelected.values_params.imgs.structure_total_width = ((2)+(2*this.paramSelected.values_params.platform.sholder)+(2*(this.paramSelected.values_params.platform.lanes*(this.paramSelected.values_params.platform.lane))))

            } else if( this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text14'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustParameters_component_text16'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustParameters_component_text16'] && this.paramSelected.values_params.platform.include_gutter_calc == false){
            this.paramSelected.values_params.imgs.aquisition = 'Road_and_lanes_1_lane_gutter_N'
            this.paramSelected.values_params.imgs.bridge = 'bridge_section_1_lane'
            this.paramSelected.values_params.imgs.cut = 'cut_1_lane'
            this.paramSelected.values_params.imgs.fill = 'fill_1_lane'
            this.paramSelected.values_params.imgs.roads = 'Road_and_lanes_1_lane_gutter_N'
            this.paramSelected.values_params.imgs.tunnel = 'tunnel_section_1_lane'
            this.paramSelected.values_params.imgs.structure_total_width = ((2*this.paramSelected.values_params.platform.sholder)+(2*(this.paramSelected.values_params.platform.lanes*(this.paramSelected.values_params.platform.lane))))

            } else if( this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustPricesDefault_component_text13'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustPricesDefault_component_text14'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustPricesDefault_component_text15']){
            this.paramSelected.values_params.imgs.aquisition = 'Road_and_lanes_1_lane_jersey'
            this.paramSelected.values_params.imgs.bridge = 'bridge_section_1_lane_jersey'
            this.paramSelected.values_params.imgs.cut = 'cut_1_lane'
            this.paramSelected.values_params.imgs.fill = 'fill_1_lane_jersey'
            this.paramSelected.values_params.imgs.roads = 'Road_and_lanes_jersey_1_lane'
            this.paramSelected.values_params.imgs.tunnel = 'tunnel_section_1_lane'
            this.paramSelected.values_params.imgs.structure_total_width = ((2)+(2*this.paramSelected.values_params.platform.sholder)+(2*(this.paramSelected.values_params.platform.lanes*(this.paramSelected.values_params.platform.lane))))

            } else if( this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustPricesDefault_component_text13'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustPricesDefault_component_text14'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustPricesDefault_component_text16']){
            this.paramSelected.values_params.imgs.aquisition = 'Road_and_lanes_1_lane_metalguard'
            this.paramSelected.values_params.imgs.bridge = 'bridge_section_1_lane_metalguard'
            this.paramSelected.values_params.imgs.cut = 'cut_1_lane'
            this.paramSelected.values_params.imgs.fill = 'fill_1_lane_metal_guard'
            this.paramSelected.values_params.imgs.roads = 'Road_and_lanes_metal_guard_1_lane'
            this.paramSelected.values_params.imgs.tunnel = 'tunnel_section_1_lane'
            this.paramSelected.values_params.imgs.structure_total_width = ((2)+(2*this.paramSelected.values_params.platform.sholder)+(2*(this.paramSelected.values_params.platform.lanes*(this.paramSelected.values_params.platform.lane))))

            }
            // yes central barrier
            if( this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustPricesDefault_component_text12'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustPricesDefault_component_text14'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustPricesDefault_component_text14']){
            this.paramSelected.values_params.imgs.aquisition = 'Road_and_lanes_2_lanes'
            this.paramSelected.values_params.imgs.bridge = 'bridge_section_2_lanes'
            this.paramSelected.values_params.imgs.cut = 'cut_2_lanes'
            this.paramSelected.values_params.imgs.fill = 'fill_2_lanes'
            this.paramSelected.values_params.imgs.roads = 'Road_and_lanes_2_lanes'
            this.paramSelected.values_params.imgs.tunnel = 'tunnel_section_2_lanes'
            this.paramSelected.values_params.imgs.structure_total_width = ((12)+(2)+(2*this.paramSelected.values_params.platform.sholder)+(4*(this.paramSelected.values_params.platform.lanes*(this.paramSelected.values_params.platform.lane))))
            } /* central e side iguais */ else if( this.paramSelected.values_params.platform.median_width <= 5 && this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustPricesDefault_component_text12'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustPricesDefault_component_text15'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustPricesDefault_component_text15']){
            this.paramSelected.values_params.imgs.aquisition = 'Road_and_lanes_2_lanes_jersey_lanes'
            this.paramSelected.values_params.imgs.bridge = 'bridge_section_2_lanes_jersey'
            this.paramSelected.values_params.imgs.cut = 'cut_2_lanes_jersey'
            this.paramSelected.values_params.imgs.fill = 'fill_2_lanes_jersey'
            this.paramSelected.values_params.imgs.roads = 'Road_and_lanes_jersey_2_lanes'
            this.paramSelected.values_params.imgs.tunnel = 'tunnel_section_2_lanes_jersey'
            this.paramSelected.values_params.imgs.structure_total_width = ((12)+(2)+(2*this.paramSelected.values_params.platform.sholder)+(4*(this.paramSelected.values_params.platform.lanes*(this.paramSelected.values_params.platform.lane))))
            } else if( this.paramSelected.values_params.platform.median_width > 5 && this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustPricesDefault_component_text12'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustPricesDefault_component_text15'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustPricesDefault_component_text15']){
            this.paramSelected.values_params.imgs.aquisition = 'Road_and_lanes_2_lanes_jersey_2'
            this.paramSelected.values_params.imgs.bridge = 'bridge_section_2_lanes_jersey_2'
            this.paramSelected.values_params.imgs.cut = 'cut_2_lanes_jersey'
            this.paramSelected.values_params.imgs.fill = 'fill_2_lanes_jersey_2'
            this.paramSelected.values_params.imgs.roads = 'Road_and_lanes_jersey_2_lanes_2'
            this.paramSelected.values_params.imgs.tunnel = 'tunnel_section_2_lanes_jersey'
            this.paramSelected.values_params.imgs.structure_total_width = ((12)+(2)+(2*this.paramSelected.values_params.platform.sholder)+(4*(this.paramSelected.values_params.platform.lanes*(this.paramSelected.values_params.platform.lane))))

            } else if( this.paramSelected.values_params.platform.median_width <= 5 && this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustPricesDefault_component_text12'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustPricesDefault_component_text16'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustPricesDefault_component_text16']){
            this.paramSelected.values_params.imgs.aquisition = 'Road_and_lanes_2_lanes_metalguard'
            this.paramSelected.values_params.imgs.bridge = 'bridge_section_2_lanes_metal_guard'
            this.paramSelected.values_params.imgs.cut = 'cut_2_lanes_metal_guard'
            this.paramSelected.values_params.imgs.fill = 'fill_2_lanes_metal_guard'
            this.paramSelected.values_params.imgs.roads = 'Road_and_lanes_metal_guard_2_lanes'
            this.paramSelected.values_params.imgs.tunnel = 'tunnel_section_2_lanes_metal_guard'
            this.paramSelected.values_params.imgs.structure_total_width = ((12)+(2)+(2*this.paramSelected.values_params.platform.sholder)+(4*(this.paramSelected.values_params.platform.lanes*(this.paramSelected.values_params.platform.lane))))

            } else if( this.paramSelected.values_params.platform.median_width > 5 && this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustPricesDefault_component_text12'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustPricesDefault_component_text16'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustPricesDefault_component_text16']){
            this.paramSelected.values_params.imgs.aquisition = 'Road_and_lanes_2_lanes_metalguard_2'
            this.paramSelected.values_params.imgs.bridge = 'bridge_section_2_lanes_metal_guard'
            this.paramSelected.values_params.imgs.cut = 'cut_2_lanes_metal_guard'
            this.paramSelected.values_params.imgs.fill = 'fill_2_lanes_metal_guard_2'
            this.paramSelected.values_params.imgs.roads = 'Road_and_lanes_metal_guard_2_lanes_2'
            this.paramSelected.values_params.imgs.tunnel = 'tunnel_section_2_lanes_metal_guard'
            this.paramSelected.values_params.imgs.structure_total_width = ((12)+(2)+(2*this.paramSelected.values_params.platform.sholder)+(4*(this.paramSelected.values_params.platform.lanes*(this.paramSelected.values_params.platform.lane))))

            } /* side none */ else if( this.paramSelected.values_params.platform.median_width <= 5 && this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustPricesDefault_component_text12'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustPricesDefault_component_text15'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustPricesDefault_component_text14']){
            this.paramSelected.values_params.imgs.aquisition = 'Road_and_lanes_2_lanes_N_J'
            this.paramSelected.values_params.imgs.bridge = 'bridge_section_2_lanes_N_J'
            this.paramSelected.values_params.imgs.cut = 'cut_2_lanes_jersey'
            this.paramSelected.values_params.imgs.fill = 'fill_2_lanes_N_J'
            this.paramSelected.values_params.imgs.roads = 'Road_and_lanes_2_lanes_N_J'
            this.paramSelected.values_params.imgs.tunnel = 'tunnel_section_2_lanes_jersey'
            this.paramSelected.values_params.imgs.structure_total_width = ((12)+(2)+(2*this.paramSelected.values_params.platform.sholder)+(4*(this.paramSelected.values_params.platform.lanes*(this.paramSelected.values_params.platform.lane))))

            } else if( this.paramSelected.values_params.platform.median_width > 5 && this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustPricesDefault_component_text12'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustPricesDefault_component_text15'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustPricesDefault_component_text14']){
            this.paramSelected.values_params.imgs.aquisition = 'Road_and_lanes_2_lanes_N_J_2'
            this.paramSelected.values_params.imgs.bridge = 'bridge_section_2_lanes_N_J'
            this.paramSelected.values_params.imgs.cut = 'cut_2_lanes_jersey'
            this.paramSelected.values_params.imgs.fill = 'fill_2_lanes_N_J_2'
            this.paramSelected.values_params.imgs.roads = 'Road_and_lanes_2_lanes_N_J_2'
            this.paramSelected.values_params.imgs.tunnel = 'tunnel_section_2_lanes_jersey'
            this.paramSelected.values_params.imgs.structure_total_width = ((12)+(2)+(2*this.paramSelected.values_params.platform.sholder)+(4*(this.paramSelected.values_params.platform.lanes*(this.paramSelected.values_params.platform.lane))))

            } else if( this.paramSelected.values_params.platform.median_width <= 5 && this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustPricesDefault_component_text12'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustPricesDefault_component_text16'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustPricesDefault_component_text14']){
            this.paramSelected.values_params.imgs.aquisition = 'Road_and_lanes_2_lanes_N_M'
            this.paramSelected.values_params.imgs.bridge = 'bridge_section_2_lanes_N_M'
            this.paramSelected.values_params.imgs.cut = 'cut_2_lanes_metal_guard'
            this.paramSelected.values_params.imgs.fill = 'fill_2_lanes_N_M'
            this.paramSelected.values_params.imgs.roads = 'Road_and_lanes_2_lanes_N_M'
            this.paramSelected.values_params.imgs.tunnel = 'tunnel_section_2_lanes_metal_guard'
            this.paramSelected.values_params.imgs.structure_total_width = ((12)+(2)+(2*this.paramSelected.values_params.platform.sholder)+(4*(this.paramSelected.values_params.platform.lanes*(this.paramSelected.values_params.platform.lane))))

            } else if( this.paramSelected.values_params.platform.median_width > 5 && this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustPricesDefault_component_text12'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustPricesDefault_component_text16'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustPricesDefault_component_text14']){
            this.paramSelected.values_params.imgs.aquisition = 'Road_and_lanes_2_lanes_N_M_2'
            this.paramSelected.values_params.imgs.bridge = 'bridge_section_2_lanes_N_M'
            this.paramSelected.values_params.imgs.cut = 'cut_2_lanes_metal_guard'
            this.paramSelected.values_params.imgs.fill = 'fill_2_lanes_N_M_2'
            this.paramSelected.values_params.imgs.roads = 'Road_and_lanes_2_lanes_N_M_2'
            this.paramSelected.values_params.imgs.tunnel = 'tunnel_section_2_lanes_metal_guard'
            this.paramSelected.values_params.imgs.structure_total_width = ((12)+(2)+(2*this.paramSelected.values_params.platform.sholder)+(4*(this.paramSelected.values_params.platform.lanes*(this.paramSelected.values_params.platform.lane))))

            } /* central e side diferentes */ else if( this.paramSelected.values_params.platform.median_width <= 5 && this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustPricesDefault_component_text12'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustPricesDefault_component_text16'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustPricesDefault_component_text15']){
            this.paramSelected.values_params.imgs.aquisition = 'Road_and_lanes_2_lanes_J_M'
            this.paramSelected.values_params.imgs.bridge = 'bridge_section_2_lanes_J_M'
            this.paramSelected.values_params.imgs.cut = 'cut_2_lanes_jersey'
            this.paramSelected.values_params.imgs.fill = 'fill_2_lanes_J_M'
            this.paramSelected.values_params.imgs.roads = 'Road_and_lanes_2_lanes_J_M'
            this.paramSelected.values_params.imgs.tunnel = 'tunnel_section_2_lanes_jersey'
            this.paramSelected.values_params.imgs.structure_total_width = ((12)+(2)+(2*this.paramSelected.values_params.platform.sholder)+(4*(this.paramSelected.values_params.platform.lanes*(this.paramSelected.values_params.platform.lane))))

            } else if( this.paramSelected.values_params.platform.median_width > 5 && this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustPricesDefault_component_text12'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustPricesDefault_component_text16'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustPricesDefault_component_text15']){
            this.paramSelected.values_params.imgs.aquisition = 'Road_and_lanes_2_lanes_J_M_2'
            this.paramSelected.values_params.imgs.bridge = 'bridge_section_2_lanes_J_M'
            this.paramSelected.values_params.imgs.cut = 'cut_2_lanes_jersey'
            this.paramSelected.values_params.imgs.fill = 'fill_2_lanes_J_M_2'
            this.paramSelected.values_params.imgs.roads = 'Road_and_lanes_2_lanes_J_M_2'
            this.paramSelected.values_params.imgs.tunnel = 'tunnel_section_2_lanes_jersey'
            this.paramSelected.values_params.imgs.structure_total_width = ((12)+(2)+(2*this.paramSelected.values_params.platform.sholder)+(4*(this.paramSelected.values_params.platform.lanes*(this.paramSelected.values_params.platform.lane))))

            } else if( this.paramSelected.values_params.platform.median_width <= 5 && this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustPricesDefault_component_text12'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustPricesDefault_component_text15'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustPricesDefault_component_text16']){
            this.paramSelected.values_params.imgs.aquisition = 'Road_and_lanes_2_lanes_M_J'
            this.paramSelected.values_params.imgs.bridge = 'bridge_section_2_lanes_M_J'
            this.paramSelected.values_params.imgs.cut = 'cut_2_lanes_metal_guard'
            this.paramSelected.values_params.imgs.fill = 'fill_2_lanes_M_J'
            this.paramSelected.values_params.imgs.roads = 'Road_and_lanes_2_lanes_M_J'
            this.paramSelected.values_params.imgs.tunnel = 'tunnel_section_2_lanes_metal_guard'
            this.paramSelected.values_params.imgs.structure_total_width = ((12)+(2)+(2*this.paramSelected.values_params.platform.sholder)+(4*(this.paramSelected.values_params.platform.lanes*(this.paramSelected.values_params.platform.lane))))

            } else if( this.paramSelected.values_params.platform.median_width > 5 && this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustPricesDefault_component_text12'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustPricesDefault_component_text15'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustPricesDefault_component_text16']){
            this.paramSelected.values_params.imgs.aquisition = 'Road_and_lanes_2_lanes_M_J_2'
            this.paramSelected.values_params.imgs.bridge = 'bridge_section_2_lanes_M_J_2'
            this.paramSelected.values_params.imgs.cut = 'cut_2_lanes_metal_guard'
            this.paramSelected.values_params.imgs.fill = 'fill_2_lanes_M_J_2'
            this.paramSelected.values_params.imgs.roads = 'Road_and_lanes_2_lanes_M_J_2'
            this.paramSelected.values_params.imgs.tunnel = 'tunnel_section_2_lanes_metal_guard'
            this.paramSelected.values_params.imgs.structure_total_width = ((12)+(2)+(2*this.paramSelected.values_params.platform.sholder)+(4*(this.paramSelected.values_params.platform.lanes*(this.paramSelected.values_params.platform.lane))))

            } /* central none */ else if( this.paramSelected.values_params.platform.median_width <= 5 && this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustPricesDefault_component_text12'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustPricesDefault_component_text14'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustPricesDefault_component_text15']){
            this.paramSelected.values_params.imgs.aquisition = 'Road_and_lanes_2_lanes_N_J'
            this.paramSelected.values_params.imgs.bridge = 'bridge_section_2_lanes_N_J'
            this.paramSelected.values_params.imgs.cut = 'cut_2_lanes_jersey'
            this.paramSelected.values_params.imgs.fill = 'fill_2_lanes_N_J'
            this.paramSelected.values_params.imgs.roads = 'Road_and_lanes_2_lanes_N_J'
            this.paramSelected.values_params.imgs.tunnel = 'tunnel_section_2_lanes_jersey'
            this.paramSelected.values_params.imgs.structure_total_width = ((12)+(2)+(2*this.paramSelected.values_params.platform.sholder)+(4*(this.paramSelected.values_params.platform.lanes*(this.paramSelected.values_params.platform.lane))))

            } else if( this.paramSelected.values_params.platform.median_width > 5 && this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustPricesDefault_component_text12'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustPricesDefault_component_text14'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustPricesDefault_component_text15']){
            this.paramSelected.values_params.imgs.aquisition = 'Road_and_lanes_2_lanes_N_J_2'
            this.paramSelected.values_params.imgs.bridge = 'bridge_section_2_lanes_N_J'
            this.paramSelected.values_params.imgs.cut = 'cut_2_lanes_jersey'
            this.paramSelected.values_params.imgs.fill = 'fill_2_lanes_N_J_2'
            this.paramSelected.values_params.imgs.roads = 'Road_and_lanes_2_lanes_N_J_2'
            this.paramSelected.values_params.imgs.tunnel = 'tunnel_section_2_lanes_jersey'
            this.paramSelected.values_params.imgs.structure_total_width = ((12)+(2)+(2*this.paramSelected.values_params.platform.sholder)+(4*(this.paramSelected.values_params.platform.lanes*(this.paramSelected.values_params.platform.lane))))

            } else if( this.paramSelected.values_params.platform.median_width <= 5 && this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustPricesDefault_component_text12'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustPricesDefault_component_text14'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustPricesDefault_component_text16']){
            this.paramSelected.values_params.imgs.aquisition = 'Road_and_lanes_2_lanes_N_M'
            this.paramSelected.values_params.imgs.bridge = 'bridge_section_2_lanes_N_J'
            this.paramSelected.values_params.imgs.cut = 'cut_2_lanes_metal_guard'
            this.paramSelected.values_params.imgs.fill = 'fill_2_lanes_N_M'
            this.paramSelected.values_params.imgs.roads = 'Road_and_lanes_2_lanes_N_M'
            this.paramSelected.values_params.imgs.tunnel = 'tunnel_section_2_lanes_metal_guard'
            this.paramSelected.values_params.imgs.structure_total_width = ((12)+(2)+(2*this.paramSelected.values_params.platform.sholder)+(4*(this.paramSelected.values_params.platform.lanes*(this.paramSelected.values_params.platform.lane))))

            } else if( this.paramSelected.values_params.platform.median_width > 5 && this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustPricesDefault_component_text12'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustPricesDefault_component_text14'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustPricesDefault_component_text16']){
            this.paramSelected.values_params.imgs.aquisition = 'Road_and_lanes_2_lanes_N_M_2'
            this.paramSelected.values_params.imgs.bridge = 'bridge_section_2_lanes_N_M'
            this.paramSelected.values_params.imgs.cut = 'cut_2_lanes_metal_guard'
            this.paramSelected.values_params.imgs.fill = 'fill_2_lanes_N_M_2'
            this.paramSelected.values_params.imgs.roads = 'Road_and_lanes_jersey_2_lanes_2'
            this.paramSelected.values_params.imgs.tunnel = 'tunnel_section_2_lanes_metal_guard'
            this.paramSelected.values_params.imgs.structure_total_width = ((12)+(2)+(2*this.paramSelected.values_params.platform.sholder)+(4*(this.paramSelected.values_params.platform.lanes*(this.paramSelected.values_params.platform.lane))))

            }
            this.paramSelected.values_params.imgs.has_central_barrier = this.selectedOptions.centralBarrierSwitch
            this.paramSelected.values_params.imgs.structure_lanes_total_width = (this.paramSelected.values_params.platform.lanes*(this.paramSelected.values_params.platform.lane))
            this.paramSelected.values_params.imgs.asphalt_depht = (Number(this.paramSelected.values_params.superstructure.first_asphalt_layer_depth)+Number(this.paramSelected.values_params.superstructure.second_asphalt_layer_depth))
        }
        }
    },
    }
    </script>
    
    <style scoped>

    .selecao{
        display: none;
        position: fixed;
        z-index: 9999;
        background-color: white;
        /* padding: 30px; */
        /* border-radius: 10%; */
        top: 25%;
        left: 50%;
        transform: translate(-50%, 0);
        width: 70vw;
        height: 50vh;
        /* overflow-y: auto; */
        -webkit-box-shadow: 10px 10px 87px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: 10px 10px 87px 0px rgba(0,0,0,0.75);
        box-shadow: 10px 10px 87px 0px rgba(0,0,0,0.75);
    }
    
        .capexLastLevel {
        font-style: italic
        }
        .capexZeroLevel {
        background-color: #0A7373;
        font-weight: bold
        }
        .capexFirstLevel {
        background-color: #338D8C;
        font-weight: bold
        }
        .capexSecondLevel {
        background-color: #36bebc;
        font-weight: bold
        }
        .capexThirdLevel {
        background-color: #41e2df;
        font-weight: bold
        }
    
        @media only screen and (max-width: 576px) {
            .codeS {
                display: none;
            }
        .trBody{
            display: flex;
            justify-content: space-between;
        }
        }
    </style>